/*-----------------------------------------------
|   Navbar
-----------------------------------------------*/
.shadow-bottom{ box-shadow: 0 0.5rem 0.5rem -0.5rem rgba($black, 0.2) !important; }

/*-----------------------------------------------
|   Navbar vertical
-----------------------------------------------*/
$navbar-vertical-width: 12.625rem;
$vartical-navbar-padding: 1.5rem;

.navbar-top{
  top: 0;
  z-index: 1020;
}
.navbar-vertical{
  padding: 0;
  z-index: 1019;
  top: $top-nav-height;
  width: 100vw;
  .navbar-brand{ display: none; }
  .navbar-collapse{
    flex-direction: column;
    margin-top: map_get($spacers, 1);
    > * {
      width: 100%;
      overflow: hidden;
    }
    .dropdown-indicator{
      position: relative;
      &:after{
        content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIwLjg3NXJlbSIgaGVpZ2h0PSIwLjg3NXJlbSIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiM1ZTZlODIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWNoZXZyb24tZG93biI+PHBvbHlsaW5lIHBvaW50cz0iNiA5IDEyIDE1IDE4IDkiPjwvcG9seWxpbmU+PC9zdmc+);
        position: absolute;
        right: 0;
        top: 0.6175rem;
        height: 0.875rem;
        width: 0.875rem;
        transition: $transition-base;
        transform-origin: center;
      }
    }
    [aria-expanded="true"]:after{ transform: rotate(180deg); }
  }
  /*-----------------------------------------------
  |   Navbar nav
  -----------------------------------------------*/
  .navbar-nav{
    width: 100%;
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    .nav-link{
      color: $gray-700;
      transition: $transition-base;
      @include media-breakpoint-up('lg'){ padding: 0.35rem 0; }
      @include hover-focus{
        color: $gray-1000;
        &.dropdown-indicator:after{ content:  url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIwLjg3NXJlbSIgaGVpZ2h0PSIwLjg3NXJlbSIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMyMzJlM2MiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBjbGFzcz0iZmVhdGhlciBmZWF0aGVyLWNoZXZyb24tZG93biI+PHBvbHlsaW5lIHBvaW50cz0iNiA5IDEyIDE1IDE4IDkiPjwvcG9seWxpbmU+PC9zdmc+); }
      }
    }
    .nav-link-icon{
      width: $vartical-navbar-padding;
      font-size: map_get($font-sizes, 0);
    }
    .nav{
      flex-flow: column nowrap;
      font-size: 0.8125rem;
      .nav-item{
        .nav-link{
          padding: 0.2rem $vartical-navbar-padding;
          &:after{ top: 0.2rem; }
        }
        &:last-child{
          margin-bottom: 0.35rem;
        }
      }
      .nav{
        .nav-item{ .nav-link{ padding-left: $vartical-navbar-padding * 1.75; } }
        .nav{ .nav-item{ .nav-link{ padding-left: $vartical-navbar-padding * 2.75; } } }
      }
    }
  }
}

/*-----------------------------------------------
|   Break 
-----------------------------------------------*/

$breaks: ('xxl', 'xl', 'lg', 'md', 'sm', 'xs');

@for $i from 1 through length($breaks){
  $item: nth($breaks, $i);
  $j: '';
  @if length($breaks) != $i{
    $j: $i + 1;
    $down: nth($breaks, $j);
    @include media-breakpoint-down($down){
      @for $k from $j through length($breaks){
        $inneritem: nth($breaks, $k);
        @if length($breaks) != $k{
          .navbar-vertical.navbar-expand-#{$item}{
            @include media-breakpoint-only($inneritem){ 
              position: fixed;
              max-width: map_get($container-max-widths, $inneritem) !important; 
              width: 100%;
            }
            & + .content{
              margin-top: $top-nav-height;
              .navbar-top {
                position: fixed;
                top: 0;
                @include media-breakpoint-only($inneritem){
                  width: 100%;
                  max-width: map_get($container-max-widths, $inneritem) !important;
                }
              }
            }
            
          }
        }
      }
      .container-fluid{
        .navbar-vertical.navbar-expand-#{$item}{
          width: 100%;
          max-width: 100vw !important;
          & + .content .navbar-top{
            width: 100%;
            max-width: 100vw !important;
          }
        }
      }
    }
    .navbar-vertical.navbar-expand-#{$item}{
      @include media-breakpoint-up($item){
        position: fixed;
        width: 100%;
        top: 0;
        overflow-y: auto;
        max-height: 100vh;
        display: block;
        max-width: $navbar-vertical-width;
        .navbar-brand{
          display: block;
          text-align: center;
        }
        & + .content{
          margin-left: $navbar-vertical-width + map_get($spacers, 5);
          .navbar-top{
            position: sticky;
            .navbar-brand, .navbar-toggler{ display: none;}
           }
        }
        // .nav-link.active{
        //   border-right: 3px solid $info;
        // }
      }
      .navbar-collapse{ box-shadow: 0 0.5rem 0.5rem -0.5rem rgba($black, 0.2); }
      @include media-breakpoint-down($down){
        
        & + .content .navbar-top .navbar-toggler{ display: block; }
        
        margin-left: -($grid-gutter-width / 2);
        margin-right: -($grid-gutter-width / 2);
        .navbar-nav{
          padding-left: $grid-gutter-width / 2;
          padding-right: $grid-gutter-width / 2;
        }
        .navbar-collapse{
          &.show{
            max-height: calc(100vh - #{$top-nav-height});
            overflow-y: auto;
          }
          .btn{
            width: calc(100% - #{$grid-gutter-width});
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}
@include media-breakpoint-only('xs'){
  .navbar-vertical{
    position: fixed;
    width: 100%;
    max-width: 100vw !important; 
  }
  .content{ margin-top: $top-nav-height; }
  .navbar-top {
    position: fixed;
    width: 100%;
    max-width: 100vw !important;
  }
}
