/*-----------------------------------------------
|   Navigation bar
-----------------------------------------------*/
.navbar-glass{ background-color: rgba($body-bg, 0.96); }
.safari{
  .navbar-glass{
    background-color: rgba($body-bg, 0.82);
    backdrop-filter: blur(5px);
  }
}
.navbar-brand{ font-weight: $navbar-brand-font-weight; }
