/*-----------------------------------------------
|   Autocomplete
-----------------------------------------------*/
.autocomplete{
  border-radius: $border-radius-capsule;
  transition: border-radius 0.1s ease-in-out;
  @include hover-focus{ outline: none; }
}
.xdsoft_autocomplete {
  .xdsoft_autocomplete_hint{ border-radius: 1.075rem; }
  .xdsoft_autocomplete_dropdown {
    border-radius: 0 0 $border-radius-soft $border-radius-soft;
    border-color: $gray-300;
    border-top-color: $gray-200;
    color: $gray-600;
    & > .active { background-color: $primary !important; }
  }
}
.search-box{
  .autocomplete{
    width: 20rem !important; 
    padding-left: 1.95rem !important; 
    background-color: #fff !important;
    
    &::-webkit-input-placeholder {
      font-size: 0.875rem;
      line-height: 1.8;
    }
  }
}

/*-----------------------------------------------
|   Search box
-----------------------------------------------*/
.search-box{
  position: relative;
  .search-input{
    width: 20rem;
    padding-left: 1.95rem;
  }
  .search-box-icon{
    z-index: 1020;
    top: 50%;
    left: 0.9rem;
    transform: translateY(-48%);
  }
}
