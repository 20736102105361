/*-----------------------------------------------
|   Backgrounds
-----------------------------------------------*/
.bg-holder {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  will-change: transform, opacity, filter;
  backface-visibility: hidden;
  background-repeat: no-repeat;
  z-index: 0;

  &.bg-right {
    left: auto;
    right: 0;
  }

  &.overlay:before {
    position: absolute;
    content: '';
    background: rgba($black, 0.25);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.overlay-primary:before {
    background: rgba($primary, 0.4);
  }
  &.overlay-info:before {
    background: rgba($info, 0.4);
  }
  &.overlay-success:before {
    background: rgba($success, 0.4);
  }
  &.overlay-warning:before {
    background: rgba($warning, 0.4);
  }
  &.overlay-danger:before {
    background: rgba($danger, 0.4);
  }
  &.overlay-0:before {
    background: rgba($black, 0.7);
  }
  &.overlay-1:before {
    background: rgba($black, 0.55);
  }
  &.overlay-2:before {
    background: rgba($black, 0.4);
  }

  .bg-video {
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    min-width: 100%;
  }

  .bg-youtube {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.bg-fixed {
  background-attachment: fixed;
}

.has-parallax {
  position: relative;
  overflow: hidden;
}

.bg-glass{
  background-color: rgba($white, 0.9);
}

/*-----------------------------------------------
|   Background Colors [Grays]
-----------------------------------------------*/
@each $key, $value in $grays {
  @include bg-variant('.bg-#{$key}', $value);
}

/*-----------------------------------------------
|   Background Colors [Brands]
-----------------------------------------------*/
@include bg-variant(".bg-facebook", $facebook);
@include bg-variant(".bg-twitter", $twitter);
@include bg-variant(".bg-google-plus", $google-plus);
@include bg-variant(".bg-linkedin", $linkedin);

.bg-card{
  background-size: contain;
  background-position: right;
}
[dir='rtl']{
  .bg-card{
    background-position: left;
    transform: scaleX(-1);
  }
}

/*-----------------------------------------------
|   Background gradient
-----------------------------------------------*/
.bg-gradient {
  background-image: linear-gradient(-45deg, #0443a2, #009bde);
  background-position: center;
}
