/*-----------------------------------------------
|   Fonts [Product Sans]
-----------------------------------------------*/
@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/product-sans/ProductSans-Bold.woff2') format('woff2'),
  url('../fonts/product-sans/ProductSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/product-sans/ProductSans-BoldItalic.woff2') format('woff2'),
  url('../fonts/product-sans/ProductSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/product-sans/ProductSans-Regular.woff2') format('woff2'),
  url('../fonts/product-sans/ProductSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Product Sans';
  src: url('../fonts/product-sans/ProductSans-Italic.woff2') format('woff2'),
  url('../fonts/product-sans/ProductSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
